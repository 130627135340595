<template>
  <footer>
    <div class="footer-top bg-primary-900 text-white p-5">
      <div class="container">
        <div class="row">
          <div
            class="col-lg-4 col-12 second flex flex-col items-center justify-center mb-4 lg:mb-1 text-center"
          >
            <img src="@/assets/logo/Rega.svg" alt="" />
            <span> رقم رخصة فال للمزادات : 4200000433 </span>
          </div>
          <div
            class="col-lg-4 col-12 first flex flex-col items-center justify-center mb-4 lg:mb-1 text-center"
          >
            <img src="@/assets/logo/footer.png" alt="" />
            <span class="text-center">
              تقوم بتسهيل عملية بيع الأصول الثابتة والمنقولة
            </span>
          </div>

          <div
            class="col-lg-4 col-12 second flex flex-col items-center justify-center mb-4 lg:mb-1"
          >
            <div class="text-xl font-bold">للتواصل عبر</div>
            <div class="mt-3">
              <unicon
                name="youtube"
                fill="#e35c65"
                width="30"
                height="30"
                class="ml-2"
              ></unicon>
              <unicon
                name="twitter"
                fill="#e35c65"
                width="30"
                height="30"
                class="ml-2"
              ></unicon>
              <unicon
                name="whatsapp"
                fill="#e35c65"
                width="30"
                height="30"
                class="ml-2"
              ></unicon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="footer-bottom text-primary-900 py-4 px-1">
        <div class="row">
          <div class="col-12 col-md-6 my-2">
            <router-link to="/terms" class="text-primary-900">
              شروط الإستخدام وإخلاء المسؤولية
            </router-link>
            |
            <router-link to="/privacy" class="text-primary-900">
              سياسة الخصوصية وسرية المعلومات
            </router-link>
          </div>
          <div class="col-12 col-md-6 my-2">
            <div class="text md:text-end">جميع الحقوق محفوظة لشركة تصفية ®</div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
footer {
  border-top: 1px solid #ccc;

  .footer-top {
    .first {
      span {
        display: block;
      }

      img {
        margin-bottom: 10px;
        width: 180px;
      }
    }
  }

  .second {
    span {
      display: block;
    }

    img {
      width: 180px;
    }
  }
}
</style>
