import Vue from "vue";
import Vuex from "vuex";
import { plainAxiosInstance } from "../main";
import Auctions from "./auctions/index";
import Auth from "./auth/index";
import Nafath from "./auth/nafath";
import Clientarea from "./auctions/clientarea";
import Alert from "./alert";
import Companies from "./companies";
import Wallet from "./wallet";
Vue.use(Vuex);


const store = new Vuex.Store({
  // plugins: [vuexLocal.plugin],

  state: {
    activeCon: "carousel",
    cloudinaryCloudName: "dygyznfnf",
    cloudinaryUploadPreset: "ssk9jn3o",
    serverTime: null,
  },
  getters: {
    activeCon:           (state) => state.activeCon,
    cloudinaryCloudName: (state) => state.cloudinaryCloudName,
    cloudinaryUploadPreset: (state) => state.cloudinaryUploadPreset,
    getServerTime:          (state) => state.serverTime,

  },
  actions : {
    fetchServerTime({ commit }) {
      return plainAxiosInstance.get('current-time')
        .then(response => {
          commit('SET_SERVER_TIME', response.data.currentTime);
        })
        .catch(error => {
          console.error('Error fetching server time:', error);
        });
    },
  },
  mutations: {
    setActiveCon(state, payload) {
      state.activeCon = payload.newActive;
    },

    SET_SERVER_TIME(state, time) {
      state.serverTime = new Date(time).getTime(); // Store as timestamp
    },
    
  },

  modules: {
    Auctions,
    Clientarea,
    Auth,
    Alert,
    Companies,
    Wallet ,
    Nafath
  },
});

export default store
