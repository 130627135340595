<template>
<div class="modal-con">
    <loading-overlay color="#484080" :active.sync="loading" :is-full-page="true" :can-cancel="true">
    </loading-overlay>

    <FormulateForm class="modal-form" @submit="submitForm" v-model="formValues" #default="{ hasErrors }">
        <h2 v-if="editMode" class="form-title">تعديل بيانات الحساب البنكي</h2>
        <h2 v-else class="form-title">اضافة حساب بنكي</h2>
        <p class="form-description">
            , يتم اضافة الحساب البنكي للعميل بغرض استرداد المبالغ علية
            لذلك نرجو التاكد من صحة البيانات المدخلة
        </p>
        <FormulateInput name="user_name" type="text" label="اسم المستخدم" help="يجب ان يكون اسم المستخدم مطابق لاسم الحساب المسجل" validation="required" />
        <FormulateInput name="acount_no" type="text" label="رقم الحساب" validation="required|number" />
        <FormulateInput name="iban_no" type="text" label="رقم الايبان" placeholder="SAxxxxxxxxxxxxxx" validation="required||max:24,length|min:24,length" @blur="getBankName" />
        <FormulateInput name="bank_name" v-model="bank_name" type="text" disabled label="اسم البنك " validation="required" />
        <FormulateInput type="file" name="file" label="الشهادة البنكية" @change="handleFileChange" accept=".jpg, .jpeg, .png, .pdf" />
        <button v-if="uploadedFileName" @click.prevent="cancelUpload"> حذف الملف </button>

        <FormulateInput v-if="editMode" type="submit" :disabled="hasErrors" label="تعديل" />

        <FormulateInput v-else type="submit" :disabled="hasErrors" label="تاكيد" />

        <div v-if="fileError" class="error">{{ fileError }}</div>
    </FormulateForm>

</div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { mapGetters, mapActions } from "vuex";
export default {
    components: {

        "loading-overlay": Loading,
    },

    computed: {

        ...mapGetters("Wallet", ["loading", "editAcountValue"]),
    },

    watch: {
        editAcountValue: {
            immediate: true,
            handler() {
                if (Object.keys(this.editAcountValue).length > 0) {
                    this.formValues = this.editAcountValue;
                    this.bank_name = this.editAcountValue.bank_name
                    this.editMode = true
                }

            },
        },
    },

    data() {
        return {
            formValues: {},
            editMode: false,
            bank_name: '',
            uploadedFileName: '', // To display the uploaded file name
            fileError: '', // To store file error messages
            banks_code: [{
                    code: '10',
                    name: 'البنك الاهلي'
                },
                {
                    code: '80',
                    name: 'مصرف الراجحي'
                },
                {
                    code: '15',
                    name: 'بنك البلاد'
                },
                {
                    code: '20',
                    name: 'بنك الرياض'
                },
                {
                    code: '05',
                    name: 'بنك الانماء'
                },
                {
                    code: '60',
                    name: 'بنك الجزيرة'
                },
                {
                    code: '30',
                    name: 'البنك العربي'
                },
                {
                    code: '40',
                    name: 'سامبا'
                },
                {
                    code: '45',
                    name: 'ساب'
                },
                {
                    code: '50',
                    name: 'السعودي الهولندي'
                },
                {
                    code: '55',
                    name: 'السعودي الفرنسي'
                },
                {
                    code: '95',
                    name: 'بنك الامارات'
                },

            ],
        }
    },

    methods: {
        ...mapActions("Wallet", ["addAccount", "setUserAccount", "resetEditValues", "editAccount"]),

        getBankName() {
            let bank_code = this.formValues.iban_no.substring(4, 6);
            let bank = this.foundBank(bank_code);
            this.bank_name = bank.name
            this.formValues.bank_name = bank.name

        },

        foundBank(bank_code) {
            return this.banks_code.find(bank =>
                bank.code == bank_code
            );
        },

        cancelUpload() {
            // Clear the file input and reset related data
            this.formValues.file = null;
            this.uploadedFileName = '';
            this.fileError = ''; // Clear any error messages
            // Reset the file input in the DOM (optional)
            this.$refs.fileInput.value = ''; // Clear the file input element
        },

        submitForm() {
            if (this.editMode) {
                this.editBankAcount()
            } else this.addBankAcount()
        },

        handleFileChange(event) {
            var reader = new FileReader();
            let file = event.target.files[0];
            if (file) {
                // Validate file size (e.g., max 2MB)
                if (file.size > 2 * 1024 * 1024) {
                    this.fileError = 'حجم الملف المرفق اكبر من 2 MB';
                    this.formValues.file = null; // Clear the file input
                    return;
                }
                this.fileError = ''; // Clear any previous errors

                reader.readAsDataURL(event.target.files[0]);
                this.formValues.extention = file["name"].substring(file["name"].indexOf("."));

                reader.onload = () => {
                    this.formValues.file = reader.result
                        .substring(reader.result.indexOf(","))
                        .substring(1);
                }; // Store the file
                this.uploadedFileName = file.name; // Display file name
            }

        },

        addBankAcount() {

            if (this.fileError) {
                this.fileError = 'الرجاء ارفاق الشهادة البنكية';
                return;
            }

            const formData = new FormData();
            formData.append('user_name', this.formValues.user_name);
            formData.append('acount_no', this.formValues.acount_no);
            formData.append('iban_no', this.formValues.iban_no);
            formData.append('bank_name', this.formValues.bank_name);
            formData.append('file', this.formValues.file);
            formData.append('extention', this.formValues.extention)
            // console.log(this.formValues.file)
            this.addAccount(formData).then(() => {
                this.$modal.hide('ModalExample')
                this.$emit('close')
                this.formValues = {};
                this.setUserAccount();
                this.opensuccessModal()
            })
        },

        editBankAcount() {
            const formData = new FormData();
            formData.append('user_name', this.formValues.user_name);
            formData.append('acount_no', this.formValues.acount_no);
            formData.append('iban_no', this.formValues.iban_no);
            formData.append('bank_name', this.formValues.bank_name);
            formData.append('file', this.formValues.file);
            formData.append('extention', this.formValues.extention)
            // console.log(this.formValues.file)
            this.editAccount(formData).then(() => {
                this.$modal.hide('ModalExample')
                this.$emit('close')
                this.formValues = {};
                this.editMode = false
                this.resetEditValues()
                this.setUserAccount();
                this.opensuccessModal()
            })
        },
        opensuccessModal() {
            Swal.fire({
                title: "تمت العملية بنجاح",
                icon: "success",
                iconColor: "#484080",
                confirmButtonColor: "#484080",
                confirmButtonText: "تم",
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.modal-con {
    direction: rtl;
}

.modal-form {
    padding: 2em;
    border-radius: .5em;
    box-sizing: border-box;
}

.form-title {
    margin-top: 0;
    font-weight: bold;
    font-size: 22px;
}

.form-description {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 14px;
}

.upload-form {
    /* Add your form styles here */
}

b .error {
    color: red;
}

.modal-form::v-deep .formulate-input .formulate-input-element {
    max-width: none;
}
</style>
