// import Vue from 'vue';
import { clientAreaAxios } from "../../main";

const state = {
  firstVisit: true,
  setdata : true ,
  opration_id: null,
  singleAuction: {},
  showAuction : {} ,
  singleProduct: {},
  lists: {
    all_auctions : [],
    auctions: {},
    auction_types: [],
    care_of_users: [],
    products: [],
    all_products : []
  },

  steps: {
    step1: {},
    step2: {},
    step3: {},
    step4: {},
  },
  loading: false,
  searchValues: {},

  info: {
    searchQuery : null,
    selected: true,
    days: 0,
    completeAddForm : false ,
    completeProductAddForm : false ,
    forShow : false ,
    isJoined :  false
  },

  addValues: {
    step1: {
      title: null,
      fall_confirm_number:null,
      start_at: null,
      end_at: null,
      region_id: null,
      city_id: null,
      des: null,
      type: null,
      award: null,
      care_of: null,
      catogry_id:1
    },
    step2: {
      card_img: null,
      cover_img: null,
      youtub_url: null,
      video: null,
    },
    step3: {},
    step4: {},
  },

  productFormData:{},

  addModalImages: [],
  editModalImages: [],
  addCardImages: [],
  addProductCardImages : [],
  addCoverImages: [],
  addBroshor:[],
  editCardImages: [],
  editCoverImages: [],
  editBroshor : [] ,
  logoImages: [],

  paginationData: {
    currentPage: undefined,
    lastPage: undefined,
  },

  PpaginationData : {}
};

const getters = {
  addValues: (state) => state.addValues,
  productFormData :(state) => state.productFormData,
  loading: (state) => state.loading,
  firstVisit: (state) => state.firstVisit,
  clientLists: (state) => state.lists,
  info: (state) => state.info,
  paginationData: (state) => state.paginationData,
  step1: (state) => state.addValues.step1,
  title: (state) => state.addValues.step1.title,
  fall_confirm_number: (state) => state.addValues.step1.fall_confirm_number,
  start_at: (state) => state.addValues.step1.start_at,
  end_at: (state) => state.addValues.step1.end_at,
  region_id: (state) => state.addValues.step1.region_id,
  city_id: (state) => state.addValues.step1.city_id,
  des: (state) => state.addValues.step1.des,
  type: (state) => state.addValues.step1.type,
  award: (state) => state.addValues.step1.award,
  care_of: (state) => state.addValues.step1.care_of,
  catogry_id :  (state) => state.addValues.step1.catogry_id,
  step2: (state) => state.steps.step2,
  step3: (state) => state.steps.step3,
  step4: (state) => state.steps.step4,
  addModalImages: (state) => state.addModalImages,
  editModalImages: (state) => state.editModalImages,
  addCardImages: (state) => state.addCardImages,
  addProductCardImages: (state) => state.addProductCardImages,
  addCoverImages: (state) => state.addCoverImages,
  addBroshor : (state) => state.addBroshor,
  editCardImages: (state) => state.editCardImages,
  editCoverImages: (state) => state.editCoverImages,
  editBroshor : (state) => state.editBroshor,
  logoImages: (state) => state.logoImages,
  singleAuction: (state) => state.singleAuction,
  singleProduct: (state) => state.singleProduct,
  showAuction : (state) => state.showAuction,
};

const actions = {
  
  async storeData({ commit, state, dispatch }) {
    state.addValues.opration_id = state.opration_id;
    commit("setLoading", true);
    commit("appendAddValuesImg");
    return new Promise((resolve, reject) => {
      clientAreaAxios
        .post("store", state.addValues)
        .then((response) => {
          commit("setLoading", false);
         
          if (response.data.status) {
            resolve(response);
            commit("resetAddValues");

            dispatch(
              "Alert/successMsg",
              {
                msg: " تم إضافة العقار بنجاح !! في إنتظار التعميد",
              },
              {
                root: true,
              }
            );
          } else {
            let massage = response.data.title;

            dispatch(
              "Alert/errorMsg",
              {
                msg: massage,
              },
              {
                root: true,
              }
            );
          }
        })
        .catch((error) => {
          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },

  async setSingleAuction({ commit, state }, id ) {
    commit("setLoading", true);
    return new Promise((resolve, reject) => {
      clientAreaAxios
        .get("single_auction/" + id + "?forShow=" + state.info.forShow)
        .then((response) => {
          commit("setLoading", false);
          commit("setSingleAuction", response.data);
          state.editCardImages = [];
          state.editCoverImages = [];
          state.editBroshor = [] ;
          commit("appendImages", {
            type: "editCardImages",
            img: response.data.card_img,
          });

          commit("appendImages", {
            type: "editBroshor",
            img: response.data.broshor,
          });

          commit("appendImages", {
            type: "editCoverImages",
            img: response.data.cover_img,
          });
          resolve(response);
        })
        .catch((err) => {
          reject(err);
          commit("setLoading", false);
          console.log(err.response);
        });
    });
  },

  async updateProduct({ state, dispatch, commit }, data) {
    commit("setLoading", true);
    return new Promise((resolve, reject) => {
      data.images_url = state.addModalImages;
      clientAreaAxios
        .patch("update_product/" + state.singleProduct.id, data)
        .then((response) => {
          console.log(response.data);
          commit("setLoading", false);
          if (response.data.status) {
            resolve(response);
            dispatch(
              "Alert/successMsg",
              {
                msg: " تم إضافة العقار بنجاح !! في إنتظار التعميد",
              },
              {
                root: true,
              }
            );
          } else {
            let massage = response.data.title;

            dispatch(
              "Alert/errorMsg",
              {
                msg: massage,
              },
              {
                root: true,
              }
            );
          }
        })
        .catch((err) => {
          reject(err);
          commit("setLoading", false);
          console.log(err.response);
        });
    });
  },

  updateAuction({ state, commit, dispatch }) {
    commit("setLoading", true);
    commit("appendEditImg");
    return new Promise((resolve, reject) => {
      clientAreaAxios
        .patch("update_auction/" + state.singleAuction.id, state.singleAuction)
        .then((response) => {
          console.log(response.data);
          commit("setLoading", false);
          if (response.data.status) {
            resolve(response);
            dispatch(
              "Alert/successMsg",
              {
                msg: " تم إضافة العقار بنجاح !! في إنتظار التعميد",
              },
              {
                root: true,
              }
            );
          } else {
            let massage = response.data.title;

            dispatch(
              "Alert/errorMsg",
              {
                msg: massage,
              },
              {
                root: true,
              }
            );
          }
        })
        .catch((err) => {
          reject(err);
          commit("setLoading", false);
          console.log(err.response);
        });
    });
  },

  

  async setSingleProduct({ commit }, id) {
    commit("resetImages" , {type : "addProductCardImages" })
    commit("setLoading", true);
    return new Promise((resolve, reject) => {
      clientAreaAxios
        .get("get_product/" + id)
        .then((response) => {
          commit("setLoading", false);
          commit("setSingleProduct", response.data);
          commit("appendImages" , {
              type : "addProductCardImages" , 
              img  : response.data.img
          })

          resolve(response);
        })
        .catch((err) => {
          reject(err);
          commit("setLoading", false);
          console.log(err.response);
        });
    });
  },

  async setAllAuctions({ commit }) {
  
    return new Promise((resolve, reject) => {
      clientAreaAxios
        .get("all_auctions")
        .then((response) => {
          commit("setLoading", false);
          commit("setAllAuctions", response.data);  
          resolve(response);
        })
        .catch((err) => {
          reject(err);
          commit("setLoading", false);
          console.log(err.response);
        });
    });
  },

  async deleteProduct({ commit, dispatch }, id) {
    commit("setLoading", true);
    return new Promise((resolve, reject) => {
      clientAreaAxios
        .delete("delete_product/" + id)
        .then((response) => {
          commit("setLoading", false);
          if (response.data.status) {
            resolve(response);
            dispatch(
              "Alert/successMsg",
              {
                msg: " تم إضافة العقار بنجاح !! في إنتظار التعميد",
              },
              {
                root: true,
              }
            );
          } else {
            dispatch("Alert/errorMsg", "", {
              root: true,
            });
          }
        })
        .catch((error) => {
          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },

  setCompleteAddForm({state} , bol){
    state.info.completeAddForm = bol;
  },

  setCompleteProductAddForm({state} , bol){
    state.info.completeProductAddForm = bol;
  },

  activateAUction({dispatch , commit} , auction_id){
    commit("setLoading", true);
    return new Promise((resolve, reject) => {
      clientAreaAxios
        .patch("activate_auction/" + auction_id)
        .then((response) => {
          commit("setLoading", false);
          if (response.data.status) {
            resolve(response);
            dispatch(
              "Alert/successMsg",
              {
                msg: "",
              },
              {
                root: true,
              }
            );
          } else {
            dispatch("Alert/errorMsg", "", {
              root: true,
            });
          }
        })
        .catch((error) => {
          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },

  async updateProductsAuction({ commit, dispatch }, data){
    return new Promise((resolve, reject) => {
      clientAreaAxios
        .patch("update_products_auction", data)
        .then((response) => {
          commit("setLoading", false);
          if (response.data.status) {
            resolve(response);
            dispatch(
              "Alert/successMsg",
              {
                msg: " تم إضافة العقار بنجاح !! في إنتظار التعميد",
              },
              {
                root: true,
              }
            );
          } else {
            let massage = response.data.title;

            dispatch(
              "Alert/errorMsg",
              {
                msg: massage,
              },
              {
                root: true,
              }
            );
          }
        })
        .catch((error) => {
          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },

  async addProduct({ commit, state, dispatch }, data) {
    commit("setLoading", true);
    data.images_url = state.addModalImages;
    data.opration_id = state.opration_id;

    return new Promise((resolve, reject) => {
      clientAreaAxios
        .post("upload_tem_product", data)
        .then((response) => {
          commit("setLoading", false);
          state.addModalImages = [];
          if (response.data.status) {
            resolve(response);
            dispatch(
              "Alert/successMsg",
              {
                msg: " تم إضافة العقار بنجاح !! في إنتظار التعميد",
              },
              {
                root: true,
              }
            );
          } else {
            let massage = response.data.title;

            dispatch(
              "Alert/errorMsg",
              {
                msg: massage,
              },
              {
                root: true,
              }
            );
          }
        })
        .catch((error) => {
          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },

  async getTemProducts({ commit, state } , auction_id = null) {
    commit("setLoading", true);
    return new Promise((resolve, reject) => {
      clientAreaAxios
        .get("get_temp_products?auction_id=" + auction_id + "&opration_id=" + state.opration_id + "&search=" + state.info.searchQuery)
        .then((response) => {
          commit("setLoading", false);
          commit("getTemProducts", response.data);
          resolve(response);
        })
        .catch((err) => {
          // Vue.prototype.$isLoading(false)
          reject(err)
          commit("setLoading", false);
          console.log(err.response);
        });
    });
  },

  setCareOfUsers({ commit }) {
    clientAreaAxios
      .get("care_of")
      .then((response) => {
        commit("setCareOfUsers", response.data);
        resolve(response);
      })
      .catch((err) => {
        // Vue.prototype.$isLoading(false)

        commit("setLoading", false);
        console.log(err.response);
      });
  },

  setLoading({ commit }, loading) {
    commit("setLoading", loading);
  },

  async setAuctions({ commit, state , dispatch }, page = 1) {
    return new Promise((resolve, reject) => {
      commit("setLoading", true);
      clientAreaAxios
        .get(
          "index?page=" +
            page +
            "&&title=" +
            state.info.title +
            "&&status=" +
            state.info.status
        )
        .then((response) => {
          state.firstVisit = false;

          // Vue.prototype.$isLoading(false)
          commit("setAuctions", response.data);
          commit("setLoading", false);
          resolve(response);
        })
        .catch((err) => {
          // Vue.prototype.$isLoading(false)
          reject(err);
          commit("setLoading", false);
          console.log(err.response);
        });
    });
  },

  setProducts({ commit, state }, page = 1){
    commit("setLoading", true);
    clientAreaAxios
      .get(
        "get_products?page=" +
          page +
          "&&title=" +
          state.info.title +
          "&&status=" +
          state.info.status
      )
      .then((response) => {
        commit("setProducts", response.data);
        commit("setLoading", false);
       
      })
      .catch((err) => {
        // Vue.prototype.$isLoading(false)
        commit("setLoading", false);
        console.log(err.response);
      });
  },

  setCatId({ state }, cat_id) {
    state.addValues.catogry_id = cat_id;
  },

  resetInfoValue({ state }) {
    state.info = {};
  },

  setOprationId({ state }) {
    if (state.opration_id == null) {
      const randomId =
        "id-" +
        Math.random().toString(36).substr(2, 9) +
        "-" +
        Date.now().toString(36);
      state.opration_id = randomId;
    }
  },

};
const mutations = {
  setAuctionTypes(state, data) {
    state.lists.auction_types = data;
  },

  setProducts(state , payload){
    
     
      state.paginationData.currentPage = payload.data.current_page;
      state.paginationData.lastPage    = payload.data.last_page;
      state.paginationData.total       = payload.data.total;
      state.info.comming               = payload.comming;
      state.info.running               = payload.running;
      state.info.finshed               = payload.finshed;
      state.lists.all_products          = payload.data.data

  },

  setSingleAuction(state, data) {
    state.singleAuction = data;
    if (state.singleAuction.care_of != null) {
      state.info.careOf = 2;
    } else state.info.careOf = 1;
  },

  setSingleProduct(state, data ) {
    state.singleProduct = data;
    if (data.imageUrl != null) {
      state.addModalImages = data.imageUrl
    }
    
  },

  getTemProducts(state, data) {
    state.lists.products = data;
  },

  setCareOfUsers(state, data) {
    state.lists.care_of_users = data;
  },

  setLoading(state, loading) {
    state.loading = loading;
  },

  appendAddValuesImg(state) {
    state.addValues.step2.card_img  = state.addCardImages[0];
    state.addValues.step2.cover_img = state.addCoverImages[0];
    state.addValues.step2.broshor   = state.addBroshor[0];

  },

  appendEditImg(state) {
    state.singleAuction.card_img  = state.editCardImages[0];
    state.singleAuction.cover_img = state.editCoverImages[0];
    state.singleAuction.broshor   = state.editBroshor[0];
  },

  setAuctions(state, payload) {
      state.lists.auctions             = payload.data.data;
      state.paginationData.currentPage = payload.data.current_page;
      state.paginationData.lastPage    = payload.data.last_page;
      state.paginationData.total       = payload.data.total;
      state.info.comming               = payload.comming;
      state.info.running               = payload.running;
      state.info.finshed               = payload.finshed;
      state.info.total                 = payload.total;
      if (payload.data.data.length == 0) state.info.noDataFound = true;
    
 
  },

  setAllAuctions(state, data){
    state.lists.all_auctions = data
  },

  setRegions(state, data) {
    state.lists.regions = data;
  },
  setCities(state, data) {
    state.lists.cities = data;
  },

  setStep1(state, payload) {
    state.steps.step1 = payload.step1;
  },

  setTitle(state, payload) {
    state.addValues.step1.title = payload.title;
  },
  setFallConfirmNumber(state, payload) {
    state.addValues.step1.fall_confirm_number = payload.fall_confirm_number;
  },
  
  setStartAt(state, payload) {
    state.addValues.step1.start_at = payload.start_at;
  },
  setEndAt(state, payload) {
    state.addValues.step1.end_at = payload.end_at;
  },
  setRegionId(state, payload) {
    state.addValues.step1.region_id = payload.region_id;
  },
  setCityId(state, payload) {
    state.addValues.step1.city_id = payload.city_id;
  },
  setDes(state, payload) {
    state.addValues.step1.des = payload.des;
  },
  setType(state, payload) {
    state.addValues.step1.type = payload.type;
  },
  setAward(state, payload) {
    state.addValues.step1.award = payload.award;
  },
  setCareOf(state, payload) {
    state.addValues.step1.care_of = payload.care_of;
  },

  setCatogry(state, payload){
    console.log(payload)
    state.addValues.step1.catogry_id = payload.catogry_id
  },

  setStep2(state, payload) {
    state.steps.step2 = payload.step2;
  },
  setStep3(state, payload) {
    state.steps.step3 = payload.step3;
  },
  setStep4(state, payload) {
    state.steps.step4 = payload.step4;
  },

  resetAddValues(state){
    state.info =  {
      selected: false,
      days: 0,
      completeAddForm : false
    }
    state.addValues = {
      step1: {
        title: null,
        fall_confirm_number:null,
        start_at: null,
        end_at: null,
        region_id: null,
        city_id: null,
        des: null,
        type: null,
        award: null,
        care_of: null,
      },
      step2: {
        card_img: null,
        cover_img: null,
        youtub_url: null,
        video: null,
      },
      step3: {},
      step4: {},
    }
  },

  appendImages(state, payload) {
    state[`${payload.type}`].push(payload.img);
  },
  removeImages(state, payload) {
    var index = state[[`${payload.type}`]].indexOf(payload.img);
    if (index !== -1) {
      state[`${payload.type}`].splice(index, 1);
    }
  },

  
  resetImages(state, payload) {
    console.log(payload)
    state[`${payload.type}`] = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
