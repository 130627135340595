// import Vue from 'vue';
import { plainAxiosInstance } from "../../main";

const state = {
  firstVisit: true,
  setdata :true ,
  currentBid : 0 ,
  lists: {
    auctions: [],
    all_auctions :[],
    auction_types: [],
    regions  : [],
    cities   : [],
    products : []
  },
  
  loading: false,
  ploading : false ,
  searchValues: {},
  info: {
    startCountDown: false,
    loading : true , 
    searchQuery : null
  },
  singleAuction: {},
  singleProduct: {
    auction : {}
  },
  paginationData: {
    currentPage: undefined,
    lastPage: undefined,
  },
};

const getters = {
  loading: (state) => state.loading,
  ploading : (state)  => state.ploading,
  firstVisit: (state) => state.firstVisit,
  lists: (state) => state.lists,
  info: (state) => state.info,
  paginationData: (state) => state.paginationData,
  singleAuction : (state) => state.singleAuction,
  singleProduct : (state) => state.singleProduct,
  currentBid    : (state) => state.currentBid

};

const actions = {
 

  joinToAuction({ commit, state, dispatch } ) {
    return new Promise((resolve, reject) => {
      commit("setLoading", true);
      plainAxiosInstance
        .post("join_to_auction/" + state.singleProduct.id)
        .then((response) => {
          commit("setLoading", false);
         
          if (response.data.status) {
            state.info.isJoined = true
            resolve(response);
            dispatch(
              "Alert/successMsg",
              {
                msg: " ",
              },
              {
                root: true,
              }
            );
          } else {
            let massage = response.data.title;

            dispatch(
              "Alert/errorMsg",
              {
                msg: massage,
              },
              {
                root: true,
              }
            );
          }
        })
        .catch((error) => {
          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },


  withdrawal({ commit, state, dispatch } ) {
    return new Promise((resolve, reject) => {
      commit("setLoading", true);
      plainAxiosInstance
        .post("withdrawal_auction/" + state.singleProduct.id)
        .then((response) => {
          commit("setLoading", false);
         
          if (response.data.status) {
            state.info.isJoined = false
            resolve(response);
            dispatch(
              "Alert/successMsg",
              {
                msg: " ",
              },
              {
                root: true,
              }
            );
          } else {
            let massage = response.data.title;

            dispatch(
              "Alert/errorMsg",
              {
                msg: massage,
              },
              {
                root: true,
              }
            );
          }
        })
        .catch((error) => {
          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },

  

  skipAuction(){

  },

  addToFav({ commit, dispatch } , data){
    commit("setLoading", true);

    return new Promise((resolve, reject) => {
      commit("completeAddValues");
      plainAxiosInstance
        .post("add_to_favs" , data)
        .then((response) => {
          commit("setLoading", false);
          if (response.data.status) {
            resolve(response);
            dispatch(
              "Alert/successMsg",
              {
                msg: "",
              },
              {
                root: true,
              }
            );
          } else {
            let massage = response.data.title;

            dispatch(
              "Alert/errorMsg",
              {
                msg: massage,
              },
              {
                root: true,
              }
            );
          }
        })
        .catch((error) => {
          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },

  resetAuctions({ state }) {
    state.lists.auctions = [];
  },

  resetInfo({ state }) {
    state.info = {};
  },

  

  setLoading({ commit }, loading) {
    commit("setLoading", loading);
  },


  async setProducts({ commit, state }, page = 1){
    return new Promise((resolve, reject) => {
      commit("setProductLoading", true);
      plainAxiosInstance
        .get("auction_products/" + state.info.auction_id + "?page=" + page + "&title=" + state.info.title)
        .then((response) => {
        
          commit("setProducts" , response.data);
          commit("setProductLoading"  , false);
          resolve(response);
         
        })
        .catch((err) => {
          // Vue.prototype.$isLoading(false)
          reject(err);
          commit("setProductLoading", false);
          console.log(err.response);
        });
    });
  },

  async setAuctions({ commit, state , dispatch }, page = 1) {
    return new Promise((resolve, reject) => {
      state.info.startCountDown = false;
      commit("setLoading", true);
      plainAxiosInstance
        .get(
          "get_auctions?page=" +
            page +
            "&&city_id=" +
            state.info.city_id +
            "&&region_id=" +
            state.info.region_id +
            "&&type_id=" +
            state.info.type_id +
            "&&status=" +
            state.info.status
        )
        .then((response) => {
          state.firstVisit = false;

          // Vue.prototype.$isLoading(false)
         
          commit("setAuctions", response.data);

        

          commit("setLoading", false);

          resolve(response);
          state.info.startCountDown = true;
        })
        .catch((err) => {
          // Vue.prototype.$isLoading(false)
          reject(err);
          commit("setLoading", false);
          console.log(err.response);
        });
    });
  },

  

  setAuctionTypes({ commit, state }) {
    if (state.lists.auction_types.length == 0) {
      plainAxiosInstance
        .get("auction_type")
        .then((response) => {
          commit("setAuctionTypes", response.data);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  },

  setRegions({ commit, state }) {
    if (state.lists.regions.length == 0) {
      plainAxiosInstance

        .get("get_regions")
        .then((response) => {
          commit("setRegions", response.data);
        })
        .catch((err) => {
          console.log(err.response);
        });
    }
  },

  setCities({ commit }, region_id) {
    if (region_id != null) {
      plainAxiosInstance
        .get("get_cities/" + region_id)
        .then((response) => {
          commit("setCities", response.data);
        })
        .catch((err) => {
          console.log(err.response);
        });
    } else {
      commit("setCities", []);
      commit("setDistricts", []);
    }
  },

  setSingleAuction({ commit , state }, auction_id) {
    commit("setLoading", state.info.loading);
    plainAxiosInstance
      .get("auction_details/" + auction_id)
      .then((response) => {
        commit("setLoading", false);
        commit("setSingleAuction", response.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  },

  setSingleProduct({ commit }, product_id) {
    commit("setLoading", true);
    plainAxiosInstance
      .get("product_details/" + product_id)
      .then((response) => {
        commit("setLoading", false);
        commit("setSingleProduct", response.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  },

  reloadProductData({ commit }, product_id){
      return new Promise((resolve, reject) => {
        plainAxiosInstance
          .get("product_details/" + product_id)
          .then((response) => {
            resolve(response);
            commit("setSingleProduct", response.data);

          })
          .catch((err) => {
            reject(err);
            console.log(err.response);
          });
      });
  },

  addBid({ commit ,  dispatch , state} , product_id ){
    commit("setLoading", true);

    return new Promise((resolve, reject) => {
      plainAxiosInstance
        .post("add_bid/" + product_id , {
          bid_price : state.currentBid
        })
        .then((response) => {
          commit("setLoading", false);
          if (response.data.status) {
            resolve(response);
            dispatch(
              "Alert/successMsg",
              {
                msg: "",
              },
              {
                root: true,
              }
            );
          } else {
            let massage = response.data.title;

            dispatch(
              "Alert/errorMsg",
              {
                msg: massage,
              },
              {
                root: true,
              }
            );
          }
        })
        .catch((error) => {
          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },
  incNumber({state}) {
    state.currentBid += state.singleProduct.bid_value;
  },

  decNumber({state}) {
    let decNumber = state.currentBid - state.singleProduct.bid_value
    var maxBid = state.singleProduct.bid_begin
    if(state.singleProduct.maxBid != null)
      maxBid = state.singleProduct.maxBid
    if (decNumber > maxBid) {
      state.currentBid = decNumber
    }
   
  },
};
const mutations = {
  setAuctionTypes(state, data) {
    state.lists.auction_types = data;
  },

  setLoading(state, loading) {
    state.loading = loading;
  },

  setProductLoading(state, loading){
    state.ploading = loading;
  },

  setProducts(state , payload){
    
     
    state.paginationData.currentPage = payload.current_page;
    state.paginationData.lastPage    = payload.last_page;
    state.paginationData.total       = payload.total;
    state.lists.products             = payload.data

},

  setAuctions(state, payload) {

   
  

  
      state.lists.auctions             = payload.data.data;
      state.paginationData.currentPage = payload.data.current_page;
      state.paginationData.lastPage    = payload.data.last_page;
      state.paginationData.total       = payload.data.total;
      state.info.comming               = payload.comming;
      state.info.running               = payload.running;
      state.info.finshed               = payload.finshed;
      state.info.total                 = payload.total;
      if (payload.data.data.length == 0) state.info.noDataFound = true;
    
   
  },

  setRegions(state, data) {
    state.lists.regions = data;
  },

  setCities(state, data) {
    state.lists.cities = data;
  },

  setSingleAuction(state, data) {
    state.singleAuction = data;
  },

  setSingleProduct(state, data) {
    state.singleProduct = data;
    state.info.isJoined = data.userParticipent
    if (data.maxBid != null) {
      state.currentBid =  parseFloat(data.maxBid) + parseFloat(data.bid_value)
    } else  state.currentBid = parseFloat(data.bid_begin)
  },

  
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
