<template>
<section class="hero">
    <div class="top-hero flex">
        <div class="right">أخبار جديدة</div>
        <div class="left">
            <div class="marquee-container">
                <VTextMarquee :speed="50" :animate="true" class="moving-text" :rtl="true">
                    <h4 v-for="(item, index) in moved_text" :key="index" class="moved-text">
                        {{ item }}
                        <span class="moved-text-img" v-if="!(moved_text.length == index + 1)">
                        </span>
                    </h4>
                </VTextMarquee>
            </div>
        </div>
    </div>

    <div class="hero-img-auc w-full relative hidden md:block" v-if="show_auction">
        <div class="img-overlay absolute top-0 left-0 w-full h-full bg-primary-600 opacity-60"></div>
        <div class="container z-20 relative">
            <div class="pt-6 font-bold text-lg">
                <span class="text-secondary-500">العد التنازلي لمزاد أرض قيقر</span>
                <span class="ms-2 text-white">20 منتج</span>
            </div>

            <div class="mz_cards_side_time_left mt-3">
                <div class="row">
                    <!-- {{ handleCount(item, index) }} -->

                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div class="mz_time_left_items">
                            <div class="mz_time_left_item mz_time_left_second">
                                <div class="mz_time_left_item_text">ثانية</div>
                                <div class="mz_time_left_item_value" :id="`${item.id}-seconds`">
                                    03
                                    <div class="absolute w-full h-px bg-primary-900 top-1/2 left-0"></div>
                                </div>
                                <div class="mz_time_left_item_dots">:</div>
                            </div>
                            <div class="time_dots">
                                <div class="time_dot t_dot"></div>
                                <div class="time_dot b_dot"></div>
                            </div>
                            <div class="mz_time_left_item mz_time_left_minute">
                                <div class="mz_time_left_item_text">دقيقة</div>
                                <div class="mz_time_left_item_value" :id="`${item.id}-minutes`">
                                    03
                                    <div class="absolute w-full h-px bg-primary-900 top-1/2 left-0"></div>
                                </div>
                                <div class="mz_time_left_item_dots">:</div>
                            </div>
                            <div class="time_dots">
                                <div class="time_dot t_dot"></div>
                                <div class="time_dot b_dot"></div>
                            </div>

                            <div class="mz_time_left_item mz_time_left_hour">
                                <div class="mz_time_left_item_text">ساعة</div>
                                <div class="mz_time_left_item_value" :id="`${item.id}-hours`">
                                    06
                                    <div class="absolute w-full h-px bg-primary-900 top-1/2 left-0"></div>
                                </div>
                                <div class="mz_time_left_item_dots">:</div>
                            </div>
                            <div class="time_dots">
                                <div class="time_dot t_dot"></div>
                                <div class="time_dot b_dot"></div>
                            </div>

                            <div class="mz_time_left_item mz_time_left_day">
                                <div class="mz_time_left_item_text">يوم</div>
                                <div class="mz_time_left_item_value day_value" :id="`${item.id}-days`">
                                    17
                                    <div class="absolute w-full h-px bg-primary-900 top-1/2 left-0"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="grid grid-cols-6 p-4 items-center justify-between z-20 relative">
            <div>
                <div class="flex text-white">
                    <img src="@/assets/icons/location2.png" alt="" class="w-6" />
                    <span class="ms-2">مدينة أبهـــا</span>
                </div>
                <div class="flex text-white mt-3">
                    <img src="@/assets/icons/hammer2.png" alt="" class="w-6" />
                    <span class="ms-2">مزاد الكترونـــي</span>
                </div>
            </div>
            <div class="col-span-4 flex justify-evenly hero-footer-imgs">
                <img src="@/assets/deb.png" alt="" />
                <img src="@/assets/deb.png" alt="" />
                <img src="@/assets/deb.png" alt="" />
            </div>
            <div class="flex justify-center">
                <button class="bg-transparent border-2 border-secondary-500 rounded-lg text-white font-bold py-1 px-6">
                    للمزيد
                </button>
            </div>
        </div>
    </div>

    <div class="hero-img w-full relative hidden md:block" v-else>
        <div class="img-overlay absolute top-0 left-0 w-full h-full bg-primary-600 opacity-60  border-b-2 border-secondary-500 "></div>

        <div class="container h-full">
            <div class="relative flex flex-col items-start justify-center h-full z-3">
                <div class="text-white text-3xl lg:text-6xl font-bold">تصفية ... أسهل</div>
                <div class="w-96 text-white pt-3 container">
                    تصفية تقوم بتسهيل عملية بيع الاصول الثابتة والمنقولة </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import { VTextMarquee } from "vue-text-marquee";

export default {
    components: {
        VTextMarquee,
    },
    data() {
        return {
            counter: 0,
            searchValues: {},
            show_auction: false,
            item: {
                id: 123,
            },
            moved_text: [
                "تصفية للمزادات ..قريبا",
                "تصفية للمزادات ..قريبا",
                "تصفية للمزادات ..قريبا",
                "تصفية للمزادات ..قريبا",
                "تصفية للمزادات ..قريبا",
                "تصفية للمزادات ..قريبا",
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.top-hero {
    
    border-bottom: 2px solid $primary;

    .right {
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $primary;
        color: white;
    }

    .left {
        width: 100%;
    }
}

.moving-text {
    color: $text;
    background-color: #e9e9e9;
    border-bottom: 1px solid #ccc;
    padding: 10px;
    direction: ltr;
}

.moved-text {
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 0;
    color: #757575;
    font-weight: normal;
}

.moved-text-img {
    img {
        height: 25px;
        margin-left: 10px;
        margin-right: 10px;
    }
}

.hero-img-auc {
    background-image: url("~@/assets/hero-min.jpg");
    background-size: cover;
    height: 100%;
}

.hero-img {
    background-image: url("~@/assets/hero-min.jpg");
    background-size: cover;
    height: 600px;
}

.hero {
    background: #b5b5b5;

    .img-text {
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .img {
            width: 100%;

            img {
                width: 100%;
            }
        }

        .text {
            font-size: 60px;
            font-weight: bold;

            span {
                color: $primary;
            }
        }
    }

    .inputs {
        padding-bottom: 60px;
        display: flex;
        width: 80%;
        margin: 50px auto 0px auto;

        .search-form {
            display: flex;
        }

        .input-con {
            width: 100%;
        }

        .input {
            width: 100%;
            height: 100%;
            border: none;
            padding: 5px;
            border-radius: 0px 5px 5px 0px;

            &::placeholder {
                color: #969696;
            }
        }

        button {
            background: $primary;
            border: none;
            color: white;
            padding: 4px 8px;
            height: 40px;
            cursor: pointer;
            border-radius: 5px 0px 0px 5px;

            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon {
                img {
                    width: 20px;
                }
            }
        }
    }
}

.mz_cards_side_time_left {
    color: white;

    //padding: 10px 15px;
    padding-top: 0px;

    //margin-top: 10px;
    .row {
        width: 100%;
        align-items: center;
        margin: 0;
    }

    .mz_time_left_items {
        display: flex;
        width: 100%;
        justify-content: space-evenly;

        .time_dots {
            margin: 0px 4px;
            font-size: 90px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 43px;

            .time_dot {
                width: 10px;
                height: 10px;
                background: white;
                border-radius: 1px;
            }

            .t_dot {
                margin-bottom: 5px;
            }

            .b_dot {
                margin-top: 5px;

            }
        }

        .mz_time_left_item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            font-size: 12px;
            width: 100%;

            .mz_time_left_item_text {
                font-size: 20px;
                margin-bottom: 6px;
            }

            .mz_time_left_item_value {
                position: relative;
                background-color: #b9b2d7;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 8px;
                font-size: 150px;
                color: white;
                font-weight: bold;
                opacity: 0.9;

                &::after {
                    //   content: ":";
                    //   margin-right: 20px;
                }
            }

            .day_value {
                &::after {
                    content: "" !important;
                    margin-right: 0px !important;
                }
            }

            .mz_time_left_item_dots {
                display: none;
                color: white;
                position: absolute;
                top: -6px;
                right: 31px;
                font-size: 25px;
            }
        }
    }
}

.hero-footer-imgs {
    img {
        width: 100px;
        height: 40px;
    }
}
</style>
