// import Vue from 'vue';
import { clientAreaAxios } from "../main";


const state = {
  loading: false,
  walletData: {},
  withdrowEditValues : {},
  invoiceNumber : undefined ,
  formData: {},
  editAcountValue : {},
 
  lists: {
    componies: {},
    auctions:  [],
    accounts : [] ,
    withdrowRequests : [] ,
    invoices : []
  },

  info: {}
};

const getters = {
  loading:        (state) => state.loading,
  lists:          (state) => state.lists,
  formData:       (state) => state.formData,
  info:           (state) => state.info,
  walletData:     (state) => state.walletData ,
  invoiceNumber : (state) => state.invoiceNumber, 
  editAcountValue : (state) => state.editAcountValue,
  withdrowEditValues : (state)=>state.withdrowEditValues

};

const actions = {
  async setWalletData({ commit, dispatch }) {
    commit("setLoading", true);
    return new Promise((resolve, reject) => {
      clientAreaAxios
        .get("get_wallet_data")
        .then((response) => {
          commit("setLoading", false);
          commit("setWalletData", response.data);

          resolve(response)
        })
        .catch((error) => {
          commit("setLoading", false);

          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },

  setAcountInfo({commit} , acount){
    commit('setAcountInfo' , acount)
  },

  setWithdrowInfo({commit} , withdrow){
    commit("setWithdrowInfo" , withdrow)
  },

  setUserInvoices({commit , dispatch}){
    commit("setLoading", true);
    return new Promise((resolve, reject) => {
      clientAreaAxios
        .get("get_user_invoices")
        .then((response) => {
          commit("setLoading", false);
          commit("setUserInvoices", response.data);

          resolve(response)
        })
        .catch((error) => {
          commit("setLoading", false);

          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },

  resetEditValues(){
    commit('setAcountInfo' , {})
  },


  async seWithdrowRequest({ commit, dispatch }) {
    commit("setLoading", true);
    return new Promise((resolve, reject) => {
      clientAreaAxios
        .get("get_withdrow_request")
        .then((response) => {
          commit("setLoading", false);
          commit("seWithdrowRequest", response.data);

          resolve(response)
        })
        .catch((error) => {
          commit("setLoading", false);

          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },

  addWithdrowRequest({ commit, dispatch } , formData) {
    commit("setLoading", true);

    return new Promise((resolve, reject) => {
      clientAreaAxios
        .post("withdrow_request" , formData)
        .then((response) => {
          commit("setLoading", false);
          if (response.data.status) {
            resolve(response);
            dispatch(
              "Alert/successMsg",
              {
                msg: "",
              },
              {
                root: true,
              }
            );
          }
        })
        .catch((error) => {
          commit("setLoading", false);
          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },


  activateWithdrowRequest({ commit, dispatch } , request_id) {
    commit("setLoading", true);

    return new Promise((resolve, reject) => {
      clientAreaAxios
        .post("activate_withdrow_request/" + request_id)
        .then((response) => {
          commit("setLoading", false);
          if (response.data.status) {
            resolve(response);
            dispatch(
              "Alert/successMsg",
              {
                msg: "",
              },
              {
                root: true,
              }
            );
          }
        })
        .catch((error) => {
          commit("setLoading", false);
          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },

  editWithdrowRequest({ commit, dispatch } , formData) {
    commit("setLoading", true);

    return new Promise((resolve, reject) => {
      clientAreaAxios
        .patch("edit_withdrow_request/"+ formData.id , formData)
        .then((response) => {
          commit("setLoading", false);
          if (response.data.status) {
            resolve(response);
            dispatch(
              "Alert/successMsg",
              {
                msg: "",
              },
              {
                root: true,
              }
            );
          }
        })
        .catch((error) => {
          commit("setLoading", false);
          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },

  setDepositePrice({commit} , price){
    commit('setDepositePrice' , price)
  },

  editAccount({ commit, dispatch }, formData){
    commit("setLoading", true);

    return new Promise((resolve, reject) => {
      clientAreaAxios
        .patch("update_account/" + formData.id, formData)
        .then((response) => {
          commit("setLoading", false);

          if (response.data.status) {
            resolve(response);
            dispatch(
              "Alert/successMsg",
              {
                msg: "",
              },
              {
                root: true,
              }
            );
          }
        })
        .catch((error) => {
          commit("setLoading", false);
          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },

  addAccount({ commit, dispatch }, formData){
    return new Promise((resolve, reject) => {
      commit("setLoading", true);
      clientAreaAxios
        .post("add_account", formData)
        .then((response) => {
          commit("setLoading", false);

          if (response.data.status) {
            resolve(response);
            dispatch(
              "Alert/successMsg",
              {
                msg: "",
              },
              {
                root: true,
              }
            );
          }
        })
        .catch((error) => {
          commit("setLoading", false);
          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },

  deavtivate({ commit, dispatch }, account_id){
    return new Promise((resolve, reject) => {
      clientAreaAxios
        .post("account_activation/" + account_id)
        .then((response) => {
          commit("setLoading", false);

          if (response.data.status) {
            resolve(response);
            dispatch(
              "Alert/successMsg",
              {
                msg: "",
              },
              {
                root: true,
              }
            );
          }
        })
        .catch((error) => {
          commit("setLoading", false);
          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },

  setUserAccount({ commit }){
    commit("setLoading", true);
    return new Promise((resolve, reject) => {
      clientAreaAxios
        .get("get_user_accounts")
        .then((response) => {
          commit("setLoading", false);
          commit("setUserAccount", response.data);
        
          resolve(response);
        })
        .catch((err) => {
          reject(err);
          commit("setLoading", false);
          console.log(err.response);
        });
    });
  },


  generateInvoice({ commit, dispatch, state }) {
    commit("setLoading", true);
    return new Promise((resolve, reject) => {
      clientAreaAxios
        .post("generate_invoice", state.formData)
        .then((response) => {

          commit("setLoading", false);
          if (response.data.status) {
            commit("setInvoiceNumber" , response.data.invoiceNumber)
            resolve(response)
          }

        })
        .catch((error) => {
          commit("setLoading", false);

          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },

  setLoading({ commit }, loading) {
    commit("setLoading", loading);
  },
};
const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },

  setAcountInfo(state , acount){
    state.editAcountValue = acount
  },

  setInvoiceNumber(state , num){
    state.invoiceNumber = num
  },

  setDepositePrice(state , price){
    state.formData.totalAmount = price
  },

  setUserInvoices(state , data){
    state.lists.invoices = data
  },

  setUserAccount(state , data){
    state.lists.accounts = data
  },

  seWithdrowRequest(state , data){
    state.lists.withdrowRequests = data
  },


  setWalletData(state, payload) {
    state.walletData = payload;

  },

  setWithdrowInfo(state, payload){
    state.editAcountValue = payload;
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
