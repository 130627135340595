// import Vue from 'vue';
import { plainAxiosInstance } from "../main";


const state = {
   loading: false,
   singleCompany : {} ,
   callNumbers : [],
   lists : {
       componies : {} ,
       auctions: [],
   },
   paginationData : {},
   info:{
     user_id : null , 
     status : undefined ,
   }
};

const getters = {
   loading :  (state) => state.loading, 
   lists   :  (state) => state.lists,
   paginationData : (state) => state.paginationData,
   info : (state) => state.info , 
   singleCompany : (state) => state.singleCompany,

   callNumbers : (state) => state.callNumbers
};

const actions = {
  async setComponies({ commit, dispatch , state } , page = 1) {
    commit("setLoading", true);
    return new Promise((resolve, reject) => {
      plainAxiosInstance
        .get("get_companies?page=" + page + "&&name=" + state.info.name)
        .then((response) => {
          commit("setLoading", false);
          commit("setComponies", response.data);

          resolve(response)
        })
        .catch((error) => {
          commit("setLoading", false);
        
          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },

  setSingleCompany({ commit , dispatch } , id){
  
    return new Promise((resolve, reject) => {
      plainAxiosInstance
        .get("get_company/" + id)
        .then((response) => {
          
          commit("setSingleCompany", response.data);
          commit('setUserId' , id)

          resolve(response)
        })
        .catch((error) => {
          
        
          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },

  setAuctions({ commit , dispatch , state } , page = 1){
    commit("setLoading", true);
    return new Promise((resolve, reject) => {
      plainAxiosInstance
        .get("get_company_auctions/" + state.info.user_id + "?page=" + page + "&status=" + state.info.status)
        .then((response) => {
          commit("setLoading", false);
          commit("setAuctions", response.data);
         

          resolve(response)
        })
        .catch((error) => {
          commit("setLoading", false);
        
          reject(error);
          dispatch("Alert/errorMsg", "", {
            root: true,
          });
        });
    });
  },

  setCallNumbers({commit} , numbers){
    commit("setCallNumbers" , numbers)
  },

  resetInfoValue({ state }) {
    state.info.status = undefined;
  },

 

  setLoading({ commit }, loading) {
    commit("setLoading", loading);
  },
};



const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },

  setCallNumbers(state, numbers){
    state.callNumbers = numbers
  },

  setUserId(state , user_id){
    state.info.user_id = user_id
  },

  setAuctions(state , payload){
    state.lists.auctions = payload.data.data
    state.paginationData.currentPage = payload.data.current_page;
    state.paginationData.lastPage    = payload.data.last_page;
    state.paginationData.total       = payload.data.total;
    state.info.comming = payload.comming;
    state.info.running = payload.running;
    state.info.finshed = payload.finshed;
    state.info.total   = payload.total
  },



  setSingleCompany(state , data){
    state.singleCompany = data

  },

  setComponies(state , payload){
    state.lists.componies = payload.data;
    state.paginationData.currentPage = payload.data.current_page;
    state.paginationData.lastPage    = payload.data.last_page;
    state.paginationData.total       = payload.data.total;
  }
 
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
