<template>
<div class="p-4 dirtl bg-gray-100">
    <div class="container">
        <div class="grid grid-cols-2">
            <div class="flex items-center justify-center">
                <div class="text-2xl font-bold bg-teal-600 text-white p-6 rounded-md">
                    نفاذ
                </div>
            </div>
            <div class="flex flex-col items-center justify-center">
                <div class="text-lg font-bold text-gray-700">تم ارسال طلبك بنجاح</div>
                <div class="w-16 h-16 bg-primary-600 text-white rounded-full mt-3 flex items-center justify-center text-2xl">
                    {{ random_number }}
                </div>
                <div class="mt-3">الوقت المتبقي: {{ timerText }}</div>
                <button @click="closeModal" class="px-4 py-2 rounded border-none bg-primary-800 text-white mt-2 text-center w-24">
                    إغلاق
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters("Nafath", ["random_number"]),
    },
    data() {
        return {
            timerText: "",
            interv: undefined,
        };
    },

    mounted() {
        this.startTimer(60);
    },
    destroyed() {
        this.stopTimer();
    },
    methods: {
        closeModal() {
            this.$modal.hide('nafathModal')
            this.$emit('close')
        },
        startTimer(duration) {
            var timer = duration;
            var minutes;
            var seconds;
            this.interv = setInterval(() => {
                minutes = parseInt(timer / 60, 10);
                seconds = parseInt(timer % 60, 10);

                minutes = minutes < 10 ? "0" + minutes : minutes;
                seconds = seconds < 10 ? "0" + seconds : seconds;

                this.timerText = minutes + ":" + seconds;

                if (--timer < 0) {
                    this.stopTimer()
                    this.$modal.hide('nafathModal')
                    this.$emit('close')
                }
            }, 1000);
        },
        stopTimer() {

            clearInterval(this.interv);
        },
    },
};
</script>

<style scoped>
.dirtl {
    direction: rtl;
}
</style>
